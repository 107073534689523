@import '../base/colors';
@import '../utils/variables';

.user-profile-subsection {
  width: 100%;

  .card-dark{
    padding: 1.5em;

    input{
      font-size: inherit;
      @media screen and (max-width: 768px){
        font-size: 12px;
      }
    }

    .button-primary{
      width: auto;
      padding: 2px 6px;
    }
  }

  .user-profile-field {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    width: 100%;

    .user-profile-label {
      margin-right: 10px;
      font-size: 16px;
      color: $light-color;
    }

    .user-profile-input-radio{
      vertical-align: middle;
    }
  }
  .user-profile-button-container{
    display: flex;
    justify-content: center;
    padding: 10px;

    .button-white{
      padding: 2px 6px;
    }
  }

  .user-profile-stats-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 10px;
    padding-bottom: 10px;

    .user-profile-stats-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .user-profile-stats-item-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        margin-bottom: auto;
      }
    }
  }
}