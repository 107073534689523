@import '../base/colors';

.nf-content {
    margin-top: 20vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
}

.nf-title {
    font-size: 6em;
    font-weight: bold;
    color: $primary-color !important;
}

.nf-text {
    font-size: 1.5em;
}
