// Colors

$primary-color: #FFDD84;
$dark-color: black;
$light-color: white;
$error-color: #da0303;
$success-color: #319e00;
$light-grey: #dadada;
$dark-grey: #11111181;
$grey-background-button: #333333;
$grey-border: #797979;
$dark-grey-border: #424242;
$background-gradient-page-bottom: black;
$background-gradient-page-top: #272727;
$background-gradient-secondary: #302f2f;
$background-gradient-tertiary: #121212;
//$secondary-color:
//$accent-color:
//$background-color:
$primary-color-light: #FFEBB7;
$text-color-base: white;
$text-color-dark: black;
$link-color: #6900ff;
$link-visited-color: #a5c300;

$overlay: rgba(0, 0, 0, 0.5);

$border-dark: black;