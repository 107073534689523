@import '../base/colors';

.ua-content {
    margin-top: 20vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
}

.ua-title {
    font-size: 4em;
    font-weight: bold;
    color: $primary-color !important;
}

.ua-text {
    font-size: 1.5em;
}
