@import "../utils/variables";

.priorpedia-search-container {
  border-style: solid;
  border-width: 1px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .priorpedia-search-fields-container {
    width: 100%;
    max-width: 800px;
    margin: 10px;

    .priorpedia-search-fields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .priorpedia-search-input {
        width: 80%;
        font-size: 20px;
        padding: 4px;
        border-radius: $border-radius-soft;
      }

      .priorpedia-search-combobox {
        margin-left: 10px;
        width: 20%;
      }
    }
  }

  .priorpedia-or-text {
    font-weight: bold;
    width: 10%;
    text-align: center;
    vertical-align: middle;
  }

  .priorpedia-random-entry-button{
    width: 20% !important;
    height: auto !important;
    margin: 10px !important;

    @media screen and (max-width: 768px){
      width: 100% !important;
    }
  }

}

.priorpedia-sort-by-container{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 20px;

  .priorpedia-sort-by-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    p{
      margin: 0 !important;
    }

    .priorpedia-sort-by-combobox {
      font-size: 18px;
      margin-left: 10px;
    }
  }

}

.priorpedia-results-container {
  width: 100%;

  .priorpedia-no-result-span {
    height: 100%;
  }

  .priorpedia-search-results-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    .priorpedia-search-results-list {
      display: flex;
      flex-direction: column;
      width: 49% !important;

      @media screen and (max-width: 768px) {
        width: 100% !important;
      }

      .card-priorpedia-list-item {
        &:hover{
          transform: scale(1.05);
          transition: $base-transition;
        }
      }

      .priorpedia-search-results-item-title-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 10px;
        height: 100%;
      }

      .priorpedia-search-results-item-views-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 10px;
        text-decoration: none;
        height: 100%;
      }
    }
  }
}

.prior-page-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .prior-page-version-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .prior-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
  }
}

.prior-page-input {
  width: 100%;
  height: auto;
  resize: none;
  font-size: 1rem;
}

.priorpedia-content-container {
  width: 90%;
}