@import '../utils/variables';

body{
  background-image: $background-gradient !important;
}

.app{
  max-width: 80%;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 768px){
    max-width: 90%
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.main{
  //min-height: calc(100vh - $height-header - $height-footer);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.content-container {
  min-height: calc(100vh - $height-header - $height-footer);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

ul{
  margin: 0 !important;
}
