@import '../base/colors';

  .page-title-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .buttons-div{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;

      @media (max-width: 768px){
        button, a{
          font-size: 14px;
        }
      }

      @media (max-width: 576px){
        flex-direction: column;
      }

      a{
        text-decoration: none !important;
      }
    }
  }


#howItWorks{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em 0;

  .card-light {
    margin: 2em 0;
    ul li {
      color: $text-color-dark;
    }

    .how-it-works-image{
      width: 100%;
      height: auto;
    }
  }

  .how-it-works-diagram {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }
}

#pricing{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em 0;

  .pricing-table-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px){
      flex-direction: column;
    }
  }

    .pricing-table-item-container {
      display: flex;
      height: 100%;
      min-height: 480px;
      width: 45%;
      align-items: center;
      justify-content: center;
      padding: 0 1em !important;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 2em;
      }

      .pricing-table-item-data-container{
        margin: 1.2em;
        padding: 1.2em;
        text-align: center;
        list-style-type: none;

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        li{
          h2, p{
            color: $text-color-dark !important;
          }
        }

        .price-per-month-container {
          display: flex;
          flex-direction: row;
          align-items:flex-start;
          justify-content: center;
          padding-bottom: 2em;

          .pricing-table-item-price {
            font-size: 3rem;
            font-weight: bold;
            color: $text-color-dark !important;
            margin-bottom: 0.3em;
            margin-right: 0.05em;
          }

          .price-per-month-text {
            text-align: left;
            line-height:110%;
            font-size: 0.7rem;
            margin-top: 1.5em;
            color: $dark-grey !important;
          }

        }
      }
    }
}
