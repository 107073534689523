@import '../base/colors';
@import '../utils/variables';

.header {
  color: $light-color;
  width: 100%;
  height: $height-header;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-img{
    width: 9em;
  }

  .profile-pic-miniature {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 1em;
  }

  .desktop-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menus {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      list-style: none;

      li:last-child .button{
        margin-right: 0 !important;
      }

      .menu-items {
        position: relative;
        font-size: 16px;
        margin-right: 1.5em;

        a {
          display: block;
          font-size: inherit;
          color: inherit;
          text-decoration: none;

          &:active{
            color: $primary-color-light
          }
        }

        &:hover{
          cursor: pointer;
          color: $primary-color;
          a{
            text-decoration: underline;
          }
        }

        @media screen and (max-width: 992px){
          margin-right: 1em;
        }
        @media screen and (max-width: 576px){
          margin-right: 0.5em;
        }

      }

      .button {
        margin-right: 1.5em;
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          margin-right: 1em;
          width: 10em;
          font-size: 12px;
        }
      }
      .dropdown{
        position: absolute;
        right: auto;
        left: auto;
        border: $border-dark-grey;
        font-size: 14px;
        z-index: 9999;
        min-width: 10rem;
        list-style: none;
        background-color: $dark-color;
        border-radius: $border-radius-soft;
        display: none;
        padding: 0 !important;

        .dropdown-items{
          padding: 8px;

          &:hover{
            transition: $base-transition;
            //color: $primary-color;
            background-color: $grey-background-button;
          }

          a{
            text-decoration: none !important;
          }
        }
      }

      .dropdown.show{
        display: block;
      }

    }
  }

  .hamburger-menu-close-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;


    .hamburger-menu-close-button, .hamburger-menu-open-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
      margin-top: 0.5em;
      background-color: transparent;
      border: none;
      color: white;
      border-radius: 50%;
      padding: 4px;

      &:hover {
        cursor: pointer;
        background-color: $dark-grey;
      }

      svg{
        width: 20px;
        height: 20px;
      }
    }
  }

  .hamburger-menu-open-button {
    margin-right: 0 !important;
    margin-top: 0 !important;
  }

  .hamburger-menu.open {
    right: -5px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $overlay;
    z-index: 99;
  }

  .hamburger-menu{
    position: fixed;
    top: 0;
    right: -305px;
    z-index: 9999;
    background-image: $background-gradient;
    border: 2px solid $grey-border;
    border-radius: 12px 0 0 12px;
    width: 300px;
    height: 100vh;
    transition: $hamburger-menu-transition;


    .hamburger-navbar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .hamburger-menus {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        list-style: none;
        width: 100%;

        .button {
          margin-bottom: 2em;
        }

        .hamburger-menu-items {
          width: 100%;

          a{
            display: block;
            font-size: inherit;
            color: inherit;
            text-decoration: none;
            margin-bottom: 2em;
            width: 100%;
            height: 100%;

            &:active{
              color: $primary-color;
            }
          }

          button{
            display: flex;
            align-items: center;
            font-size: inherit;
            color: inherit;
            border: none;
            background-color: transparent;
            cursor: pointer;
            width: 100%;
          }
        }
      }
    }
  }

}