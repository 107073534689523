@import '../base/colors';

.how-it-works-text {
  margin: 3rem 0;

  h1, h2 {
    color: $primary-color !important;
    padding-bottom: 0.5rem;
  }
  .privacy-policy-section {
    padding: 2rem 0;

  }
}