@import '../base/colors';
@import '../utils/variables';

.contact-us-form{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  padding: 1em;

  .form-label{
    @extend .text--md;
    padding-top: 2em;
    margin-bottom: 0.25em !important;
    color: $primary-color
  }

  .form-text-input{
    padding: 0.5em;
    border-radius: $border-radius-soft;
  }

  .form-text-area{
    padding: 0.5em;
    border-radius: $border-radius-soft;
    min-height: 4em;
  }

  .form-error{
    color: $error-color;
  }

  .success-message{
    color: $success-color;
  }
}