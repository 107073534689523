@import './colors';

:root {
  --font-primary:  "Gill Sans";
  --font-secondary: serif;

  /* set base values */
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;

  /* type scale */
  --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
  --text-sm: calc(1em / var(--text-scale-ratio));
  --text-md: calc(1em * var(--text-scale-ratio));
  --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xxl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xxxl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));

  /* line-height */
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: var(--text-base-size);
  color: $text-color-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1, h2, h3, h4, form legend, span {
  color: $text-color-base !important;
  margin-bottom: var(--space-xxs) !important;
  line-height: var(--heading-line-height) !important;
}

h1{
  color: $primary-color !important;
}

/* text size */
.text--xxxl {
  font-size: var(--text-xxxl);
}

h1, .text--xxl {
  font-size: var(--text-xxl);
}

h2, .text--xl {
  font-size: var(--text-xl);
}

h3, .text--lg {
  font-size: var(--text-lg);
}

h4, .text--md {
  font-size: var(--text-md);
}

.text--sm, small {
  font-size: var(--text-sm);
}

.text--xs {
  font-size: var(--text-xs);
}

p {
  line-height: var(--body-line-height);
  color: $text-color-base;
}

a {
  color: var(--color-link);

  &:visited {
    color: var(--color-link-visited);
  }
}

b, strong {
  font-weight: bold;
}

ul{
  li{
    color: $text-color-base;
  }
}


/* utility classes */
.truncate {
  // truncate text if it exceeds parent
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* color classes */
.dark-text{
  color: $text-color-dark !important;
}

/* Custom typography */

.normal-title-text {
  color: $text-color-base;
}

.highlighted-title-text {
  color: $text-color-dark !important;
  background-color: $primary-color;
  padding: 0 6px;
}



/* Light-theme */

//.theme--light {
//  // not supported yet, but soon!
//  --color-text: var(--gray-1);
//  --color-text-heading: var(--white);
//  --color-text-subtle: var(--gray-4);
//  --color-link: var(--color-accent);
//}
//
//.theme--light {
//  // what we can do for now
//  color: var(--gray-1);
//
//  h1, h2, h3, h4 {
//    color: var(--white);
//  }
//
//  .text--subtle {
//    color: var(--gray-4);
//    color: var(--gray-4);
//  }
//
//  a {
//    color: var(--color-accent);
//  }
//}