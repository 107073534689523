@import '../base/colors';
@import '../utils/variables';


.scroll-to-top-button {
    position: fixed;
    bottom: 5vh;
    right: 10vw;
    padding: 10px 20px;
    background-color: $primary-color;
    border: none;
    border-radius: 30%;
    cursor: pointer;

    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 1);;
    &:hover{
        transition: $base-transition;
        background-color: $primary-color-light;
    }
    }
