
.contact-button-group{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;

  .contact-link{
    padding: 0 1em;
    width: 70px;
    height: auto;
    text-align: center;

    &:hover{
      color: $primary-color-light;
    }

    .contact-icon{
      color: $primary-color;
      width: 100%;
      height: auto;
    }
  }
}