@import '../base/colors';
@import '../utils/variables';

.button{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  width: 10em;
  padding: 0.5em;
  border-radius: $border-radius-button;

  &:hover {
    transition: $base-transition;
    cursor: pointer;
  }
}

.button-primary{
  @extend .button;
  background-color: $primary-color;

  &:hover{
    background-color: $primary-color-light;
  }
}

.button-dark{
  @extend .button;
  background-color: transparent;
  border: 1px solid $light-color;
  color: $light-color !important;

  &:hover{
    background-color: $grey-background-button;
  }
}

.button-white{
  @extend .button;
  background-color: $light-color;
  border: 1px solid $border-dark-grey;
  color: $dark-color !important;

  &:hover{
    background-color: $light-grey;
  }
}

.button-priorpedia{
  @extend .button;
  width: 100%;
  margin-top: 10px;
  background-color: $primary-color;
  color: $dark-color;
  border-radius: $border-radius-soft;
  font-weight: bold;
  height: 40px;
  font-size: 20px;

  &:hover{
    background-color: $primary-color-light;
    cursor: pointer;
  }
}

.button-cancel{
  @extend .button;
  background-color: $dark-grey;
  border-radius: $border-radius-soft;
  height: auto;
  color: white;
}