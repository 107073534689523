@import '../base/colors';

// Heights
$height-header: 9rem;
$height-footer: 7rem;

// Screen sizes

//$sm:
//$md:
//$lg:
//$x-lg:

// Shadow
$box-shadow-light: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
$box-shadow-dark: 0 1rem 3rem rgba(0, 0, 0, 0.175);

// border-radius
$border-radius-soft: 0.5rem;
$border-radius-hard: 1rem;
$border-radius-very-hard: 1.5rem;
$border-radius-button: 22px;

// borders

$border-dark-grey: 1px solid $dark-grey-border;

//transitions
$base-transition: 0.4s ease;
$hamburger-menu-transition:  all 0.3s linear;

// background
$background-gradient: linear-gradient($background-gradient-page-top, $background-gradient-page-bottom);