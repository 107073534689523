.home-page-title-container{
  font-weight: bold;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3em 0;
}

.home-title-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 2em;

  @media screen and (max-width: 576px){
    flex-direction: column;
  }
}

.home-buttons-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .button-primary{
    margin-right: 3em;
  }

  @media screen and (max-width: 576px){
    flex-direction: column;

    .button-primary{
      margin-right: 0;
      margin-bottom: 2em;
    }
  }
}

.home-page-content-title {
  padding: 24px 0;
  color: $text-color-base;
}

.home-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 576px){
    margin-left: 10%;
    margin-right: 10%;
  }
}