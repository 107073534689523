@import '../base/colors';
@import '../utils/variables';
@import '../components/cards';

.team-presentation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 768px){
    flex-direction: column;
  }
}

.team-cards-container {
  @extend .card-dark;
  width: 45%;
  margin: 1em 2em 2em 0;

  @media screen and (max-width: 768px){
    width: 100%;
  }
}

.wanted-team-cards-container {
  @extend .card-dark;
  width: 100%;
  border: 2px dashed $primary-color;
  margin: 1em 0 2em 0;
  position: relative;

  .team-member-name{
    margin: 0 0.5em 0 0 !important;
  }
}

.team-card-image-container {
  overflow: hidden;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: $box-shadow-light;
}

.team-card-image {
  margin-top: -18px;
  width: 100%;
  height: auto;
}

.team-member-name{
  color: $text-color-base;
  font-weight: bold;
  margin: 0.5em 0 0 0 !important;
}

.team-member-position{
  color: $text-color-dark !important;
  background-color: $primary-color;
  font-weight: bold;
  padding: 0 8px;
  margin: 0.5em 0 !important;
}