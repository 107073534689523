@import "../base/colors";
@import "../utils/variables";

.card{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $border-radius-very-hard !important;
  border: 2px solid $dark-grey-border;
  box-shadow: $box-shadow-dark;
  padding: 2.5em;

  @media screen and (max-width: 768px){
    padding: 1.5em;
  }

  @media screen and (max-width: 576px){
    padding: 1em;
  }
}

.card-light{
  @extend .card;
  background-image: linear-gradient(to top right, $primary-color, $primary-color-light);
}

.card-dark{
  @extend .card;
  background-image: linear-gradient(to top right, $background-gradient-tertiary, $background-gradient-secondary);
}

.card-white{
  @extend .card;
  background-color: white;
  color: $text-color-dark !important;
}

.card-priorpedia-list-item{
  @extend .card-white;
  border-radius: $border-radius-soft !important;
  padding: 0.5em !important;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  height: 8em;
}