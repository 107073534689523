@import '../base/colors';
@import '../utils/variables';

.footer-container {
  height: $height-footer;
  display: flex;
  align-items: center;
  justify-content: center;

  a{
    color: $primary-color-light;
  }
}